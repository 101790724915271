import React from 'react';
import { CarouselCardsSection, CarouselCardsSectionProps } from '@solublestudio/legalbrave-design-system';

const TestimonialsSection = (props: Partial<CarouselCardsSectionProps>) => {
    const customProps = {
        showControls: true,
        alignControls: 'center',
        size: 'medium',
        theme: 'bg-primary',
    }
    return <CarouselCardsSection {...props} {...customProps} />;
};

export default TestimonialsSection;
